import { useClaims } from "../../../hooks/useClaims";
import useUserClaims from "../../../hooks/useUserClaims";
import { ActionStatus } from "../../../interfaces/enums/ActionStatusEnums";
import ActionStatusComponent, {
  getActionStatus,
} from "../../Common/ActionStatus/ActionStatus";
import { BlockLabel } from "../VerifyBlock/VerifyBlock.styled";
import {
  ActionsBlockContainer,
  ActionsListContainer,
  ActionStatusIndicator,
  ListItemContainer,
} from "./ActionBlock.styled";

const ActionsBlock = () => {
  const { actions } = useClaims();
  const { userClaims } = useUserClaims();

  function renderStatus(status: ActionStatus) {
    return <ActionStatusIndicator status={status} />;
  }
  return (
    <ActionsBlockContainer>
      <BlockLabel>Actions on site</BlockLabel>
      <ActionsListContainer>
        {actions &&
          actions.actions.map((action) => {
            return (
              <ListItemContainer
                status={getActionStatus(action, userClaims)}
                key={action.actionName}
              >
                <ActionStatusComponent
                  action={action}
                  userClaims={userClaims}
                  renderStatus={renderStatus}
                />
                {action.actionName}
              </ListItemContainer>
            );
          })}
      </ActionsListContainer>
    </ActionsBlockContainer>
  );
};

export default ActionsBlock;
