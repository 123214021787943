import { NotificationWrapper } from "../GatekeeperModal/GatekeeperModal.styled";
import { Spinner } from "../../Common/Spinner/Spinner";

export const ErrorNotification = () => {
  return <NotificationWrapper>Error occured</NotificationWrapper>;
};

export const LoadingNotification = () => {
  return (
    <NotificationWrapper>
      <Spinner size={40} />
    </NotificationWrapper>
  );
};
