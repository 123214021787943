import { useState } from "react";
import { useNetwork, useNetworkMismatch, useSigner } from "@thirdweb-dev/react";
import { CHAIN_ID, CLAIM_ISSUER } from "../web3/constants/contractAddresses";
import IDENTITY_ABI from "../web3/abi/Identity.json";
import { IClaim } from "../interfaces/IClaims";
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { ERRORS } from "../interfaces/enums/ErrorEnums";
import { getErrorMessage } from "../utils/getErrorMessage";

export function useSaveToBlockchain() {
  const signer = useSigner();
  const [, switchNetwork] = useNetwork();
  const isWrongNetwork = useNetworkMismatch();
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [error, setError] = useState<ERRORS | undefined>(undefined);

  const deploySelfIdentityWithClaim = async (
    identity: string,
    claimTopicId: IClaim["claimTopicId"],
    signature: IClaim["signature"],
    data: IClaim["claimData"],
    claimId: IClaim["id"]
  ) => {
    setError(undefined);
    try {
      if (!signer) {
        throw new Error("Signer is undefined");
      }

      if (isWrongNetwork && switchNetwork) {
        const { error } = await switchNetwork(CHAIN_ID);
        if (error) {
          throw new Error(ERRORS.WRONG_NETWORK);
        }
        window.location.reload();
        return;
      }
      setIsSaveLoading(true);
      const sdk = ThirdwebSDK.fromSigner(signer);
      const contract = await sdk.getContract(identity!, IDENTITY_ABI);

      const result = await contract.call(
        "addClaim",
        claimTopicId,
        1,
        CLAIM_ISSUER,
        signature,
        data,
        ""
      );
      setIsSaveLoading(false);
      console.log("claim saved successfully", result);
    } catch (error) {
      console.log("error while saving claim", error);
      const errorMessage = getErrorMessage(error);
      setError(errorMessage);
      setIsSaveLoading(false);
    }
  };

  return {
    saveError: error,
    isSaveLoading,
    saveDataToBlockchain: deploySelfIdentityWithClaim,
  };
}
