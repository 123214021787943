import styled from "styled-components";
import { BlockContainer } from "../../../styles/globalStyles";

export const ExchangeSectionContainer = styled(BlockContainer)`
  width: 100%;
  min-height: 18.625rem;
  display: flex;
  flex-direction: row;
  gap: 3.5rem;
  padding: 1.5rem 1.5rem 1.5rem 1rem;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: stretch;
`;


