import ActionsSection from "../../components/DemoPage/ActionsSection/ActionsSection";
import ExchangeSection from "../../components/DemoPage/ExchangeSection/ExchangeSection";
import { Container } from "../../styles/globalStyles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LandingPage = () => {
  return (
    <Container>
      <ExchangeSection />
      <ActionsSection />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Container>
  );
};

export default LandingPage;
