import { useState } from "react";
import Footer from "./components/DemoPage/Footer/Footer";
import Navbar from "./components/DemoPage/Navbar/Navbar";
import LandingPage from "./pages/LandingPage";
import GlobalStyle from "./styles/globalStyles";
import { ThirdwebProvider, ChainId, useUser } from "@thirdweb-dev/react";
import { API_ENDPOINT } from "./api/apiConfig";
import { ThemeProvider } from "styled-components";
import { demoPageTheme } from "./styles/themes/demoPageTheme";
import { Modal } from "./components/Common/Modal/Modal";
import GatekeeperModal, {
  IExtendedUser,
} from "./components/Gatekeeper/GatekeeperModal/GatekeeperModal";
import ConnectWalletModal from "./components/DemoPage/ConnectWalletModal/ConnectWalletModal";
import { widgetTheme } from "./styles/themes/widgetTheme";
import { ModalType } from "./interfaces/enums/PortalEnums";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WebSocketProvider } from "./context/WebSocketContext";

const activeChainId = ChainId.Mumbai;

const queryClient = new QueryClient();

function App() {
  const [activeModal, setActiveModal] = useState(ModalType.None);
  const openModal = (modalType: ModalType) => {
    setActiveModal(modalType);
  };
  const closeModal = () => {
    setActiveModal(ModalType.None);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThirdwebProvider
        activeChain={activeChainId}
        queryClient={queryClient}
        authConfig={{
          domain: process.env.REACT_APP_THIRDWEB_AUTH_DOMAIN!,
          authUrl: `${API_ENDPOINT}/auth`,
        }}
      >
        <WebSocketProvider>
          <ThemeProvider theme={demoPageTheme}>
            <GlobalStyle />
            <Navbar openModal={openModal} closeModal={closeModal} />
            <LandingPage />
            <Footer />
            <Modal
              isOpen={activeModal === ModalType.ConnectWallet}
              handleClose={closeModal}
            >
              <ConnectWalletModal handleClose={closeModal} />
            </Modal>
            <Modal
              isOpen={activeModal === ModalType.Widget}
              handleClose={closeModal}
            >
              <ThemeProvider theme={widgetTheme}>
                <GatekeeperModal handleClose={closeModal} />
              </ThemeProvider>
            </Modal>
          </ThemeProvider>
        </WebSocketProvider>
      </ThirdwebProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
