import { useQueryClient } from "@tanstack/react-query";
import { useUser } from "@thirdweb-dev/react";
import React from "react";
import { useEffect, useRef, useState } from "react";

type WebSocketContextType = {
  ws: WebSocket | null;
  newIdentity: string | null;
  setUserAddress: React.Dispatch<React.SetStateAction<string | null>>;
};

const WebSocketContext = React.createContext<WebSocketContextType>({
  ws: null,
  newIdentity: null,
  setUserAddress: () => null,
});

type WebSocketProviderProps = {
  children: React.ReactNode;
};
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [newIdentity, setNewIdentity] = useState<string | null>(null);
  const heartbeatIntervalRef = useRef<NodeJS.Timer | null>(null);
  const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const connect = () => {
    if (userAddress) {
      if (ws) disconnect();
      const newWs = new WebSocket(WEBSOCKET_URL!);
      setWs(newWs);

      newWs.onopen = () => {
        const initialMessage = JSON.stringify({
          type: "login",
          userAddress,
        });
        newWs.send(initialMessage);
      };

      newWs.onmessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data);

        switch (message.type) {
          case "IdentityUpdated":
            setNewIdentity(message.identityAddress);
            break;
          case "ClaimsUpdated":
            queryClient.invalidateQueries({
              queryKey: ["userClaimsList"],
            });
            break;
        }
      };

      heartbeatIntervalRef.current = setInterval(() => {
        if (newWs.readyState === WebSocket.OPEN) {
          newWs.send(JSON.stringify({ type: "_heartbeat_" }));
        }
      }, 60000);

      newWs.onclose = () => {
        if (!(newWs as any).requested_shutdown) {
          clearInterval(heartbeatIntervalRef.current!);
          reconnect();
        }
      };
    }
  };

  const reconnect = () => {
    const delay = 5000;
    reconnectIntervalRef.current = setTimeout(() => {
      connect();
      reconnectIntervalRef.current = null;
    }, delay);
  };

  const disconnect = () => {
    if (ws) {
      (ws as any).requested_shutdown = true;
      ws.close();
    }
    setWs(null);
    setNewIdentity(null);
  };

  useEffect(() => {
    disconnect();
    if (userAddress) {
      connect();
    }
  }, [userAddress]);

  useEffect(() => {
    disconnect();
    return () => {
      clearInterval(heartbeatIntervalRef.current!);
      clearTimeout(reconnectIntervalRef.current!);
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ ws, newIdentity, setUserAddress }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;
