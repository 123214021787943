import { useQuery } from "@tanstack/react-query";
import { getUserClaims } from "../api";
import { useUser } from "@thirdweb-dev/react";
import { useClaims } from "./useClaims";

const useUserClaims = () => {
  const { claims } = useClaims();
  const { user, isLoggedIn } = useUser();

  const {
    data: userClaims,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["userClaimsList", user?.address],
    queryFn: getUserClaims,
    enabled: isLoggedIn && !!claims && !!user?.address,
  });

  return {
    userClaims,
    isLoading,
    isError,
    error,
  };
};

export default useUserClaims;
