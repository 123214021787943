import { clientConfig } from "../../../data/mockData";
import {
  FooterContainer,
  FooterSection,
  InlineRow,
  StyledFooter,
} from "./Footer.styled";

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <FooterSection>
          <span className="title">SuperExchange</span>
          <InlineRow>
            <span>© {clientConfig.name} 2023</span>
          </InlineRow>
        </FooterSection>
        <FooterSection>
          <span className="title">Quick actions</span>
          <InlineRow>
            {clientConfig.mockActionList.map((action) => (
              <a key={action.actionName}>{action.actionName}</a>
            ))}
          </InlineRow>
        </FooterSection>
        <FooterSection>
          <span className="title">Contact</span>
          <InlineRow>
            <a>{clientConfig.phoneNumber}</a>
            <a>{clientConfig.email}</a>
          </InlineRow>
        </FooterSection>
        <FooterSection>
          <a className="last">Help</a>
        </FooterSection>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
