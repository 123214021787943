export enum PortalTarget {
  MODAL = "modal-portal",
  ROOT = "root",
}

export enum ModalType {
  None,
  ConnectWallet,
  Widget,
}
