import styled from "styled-components";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
  STATUS_COLORS,
} from "../../../styles/variables";
import { VerifyBlockContainer } from "../VerifyBlock/VerifyBlock.styled";
import { UnstyledButton } from "../../Common/Buttons/UnstyledButton.styled";

interface Props {
  closed: boolean;
}

export const GatekeeperModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  max-width: 29rem;
  width: 100%;
  min-height: 43.438rem;
  border-radius: ${BORDER_RADII.extraLarge};
  z-index: 2;
  padding: 2rem;
  gap: 2rem;
  box-shadow: ${({ theme }) => theme.boxShadow};

  * {
    font-family: ${({ theme }) => theme.fontFamily};
  }
`;

export const NotificationWrapper = styled.div`
  margin-top: 50%;
`;

export const WidgetTopSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.797rem;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.654rem;
  position: relative;
  width: 100%;
`;

export const DisconnectBtn = styled.button`
  position: absolute;
  border: none;
  background: none;
  outline: none;
  color: ${({ theme }) => theme.colors.buttons.ghost.color};
  font-weight: ${FONT_WEIGHTS.medium};
  font-size: ${FONT_SIZES.small};
  top: 0;
  right: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.buttons.ghost.hoverColor};
  }
`;

export const VerifyBlocksSection = styled.section`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  max-height: 25rem;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(167, 169, 178, 0.5);
    border-radius: ${BORDER_RADII.medium};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${BORDER_RADII.medium};
    background-color: rgba(167, 169, 178, 0.8);
  }
`;

export const RestartBtn = styled(DisconnectBtn)`
  top: 60%;
`;
export const ErrorMessageBlock = styled(VerifyBlockContainer)<Props>`
  display: ${({ closed }) => (closed ? "none" : "flex")};
  margin: auto 0;
  margin-bottom: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${STATUS_COLORS.denied};
  font-weight: ${FONT_WEIGHTS.medium};
  font-size: ${FONT_SIZES.small};
  color: ${({ theme }) => theme.colors.text.label};
  position: relative;
  padding: 0.75rem 1rem;
`;
export const CloseIconButton = styled(UnstyledButton)`
  position: absolute;
  right: 2%;
  top: 20%;
`;
