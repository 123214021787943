import { ResultStatus } from "../interfaces/enums/ResultStatusEnums";
import { IClaim, ClaimStatus } from "../interfaces/IClaims";

export function processClaim(claim: IClaim | undefined): ResultStatus {
  if (!claim) {
    return ResultStatus.UNKNOWN;
  }
  switch (claim.status as ClaimStatus) {
    case "Created":
      return ResultStatus.CREATED;
    case "Started":
      return ResultStatus.STARTED;
    case "Processing":
      return ResultStatus.IN_REVIEW;
    case "BlockedRequiresUserAction":
      return ResultStatus.PENDING;
    case "PreparedToSaveData":
      return ResultStatus.PENDING;
    case "Rejected":
      return ResultStatus.REJECTED;
    case "Saved":
      return ResultStatus.VERIFIED;
    default:
      return ResultStatus.UNKNOWN;
  }
}
