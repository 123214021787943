import styled from "styled-components";
import { BlockContainer } from "../../../styles/globalStyles";
import {
  FONT_SIZES,
  FONT_WEIGHTS,
} from "../../../styles/variables";

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  width: 100%;
  padding: 2rem 0 1.5rem 0;
`;

export const NavbarInnerBlockContainer = styled(BlockContainer)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.5rem 0.75rem 1rem;
  min-height: 4.438rem;
`;

export const ClientSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;

  & > span {
    color: ${({ theme }) => theme.colors.text.title};
    font-weight: ${FONT_WEIGHTS.bold};
    font-size: ${FONT_SIZES.extraLarge};
  }
`;

export const ConnectGatekeeperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.75rem;
  justify-content: center;
  align-items: center;
`;