import { useState, useEffect } from "react";
import { clientConfig, IMockActionClaims } from "../../../data/mockData";
import {
  ActionBlock,
  ActionDescription,
  ActionInfoWrapper,
  ActionsSectionContainer,
  ActionTitle,
  AvailabilityIndicator,
  AvailabilityInfoWrapper,
  TakeActionBtn,
} from "./ActionsSection.styled";
import logo from "../../../assets/Gatekeeper.svg";
import { ActionStatus } from "../../../interfaces/enums/ActionStatusEnums";
import ActionStatusComponent, {
  getActionStatus,
} from "../../Common/ActionStatus/ActionStatus";
import { useAddress, useUser } from "@thirdweb-dev/react";
import useUserClaims from "../../../hooks/useUserClaims";
import { toast } from "react-toastify";
import {
  IActionClaims,
  IActionsConfiguration,
} from "../../../interfaces/IClaims";
import { useClaims } from "../../../hooks/useClaims";
import { useClientActions } from "../../../hooks/useClientActions";

const ActionsSection = () => {
  const { isLoggedIn } = useUser();
  const address = useAddress();
  const { actions } = useClaims();
  const { userClaims } = useUserClaims();
  const { deposit, withdraw, isLoading } = useClientActions();

  const [mockActionList, setMockActionList] = useState<IMockActionClaims[]>(
    clientConfig.mockActionList
  );

  function mergeClaimTopics(
    mockActionList: IMockActionClaims[],
    actions: IActionsConfiguration
  ) {
    return mockActionList.map((mockedAction) => {
      const matchingAction = actions.actions.find(
        (action) => action.actionName === mockedAction.actionName
      );

      if (matchingAction) {
        return {
          ...mockedAction,
          claimTopics: matchingAction.claimTopics,
        };
      } else {
        return mockedAction;
      }
    });
  }

  const handleActionClick = async (
    actionName: IMockActionClaims["actionName"]
  ) => {
    if (actionName === "Deposit") {
      await toast.promise(deposit(), {
        pending: "Transaction is pending",
      });
    } else if (actionName === "Withdraw") {
      await toast.promise(withdraw(), {
        pending: "Transaction is pending",
      });
    } else {
      return;
    }
  };

  useEffect(() => {
    if (actions) {
      const updatedActions = mergeClaimTopics(mockActionList, actions);
      setMockActionList(updatedActions);
    }
  }, [actions]);

  function renderStatus(status: ActionStatus) {
    if (status === ActionStatus.DEFAULT) {
      return null;
    }
    return (
      <AvailabilityIndicator status={status}>{status}</AvailabilityIndicator>
    );
  }

  const checkIfDisabled = (action: IActionClaims) => {
    if (action.claimTopics.length === 0) {
      return false;
    } else if (
      !address ||
      !isLoggedIn ||
      (isLoggedIn && getActionStatus(action, userClaims)) !==
        ActionStatus.ALLOWED
    ) {
      return true;
    } else return false;
  };

  return (
    <ActionsSectionContainer>
      {mockActionList.map((action) => (
        <ActionBlock key={action.actionName}>
          <ActionInfoWrapper>
            <ActionTitle>{action.actionName}</ActionTitle>
            <ActionDescription>{action.description}</ActionDescription>
            <TakeActionBtn
              disabled={checkIfDisabled(action)}
              onClick={() => handleActionClick(action.actionName)}
            >
              {action.actionName}
            </TakeActionBtn>
          </ActionInfoWrapper>
          <AvailabilityInfoWrapper>
            {address && isLoggedIn && action.claimTopics.length !== 0 && (
              <ActionStatusComponent
                action={action}
                userClaims={userClaims}
                renderStatus={renderStatus}
              />
            )}
            {action.claimTopics.length !== 0 && (
              <img src={logo} alt="Gatekeeper logo" />
            )}
          </AvailabilityInfoWrapper>
        </ActionBlock>
      ))}
    </ActionsSectionContainer>
  );
};

export default ActionsSection;
