import React from "react";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton/PrimaryButton";
import { ActionTitle } from "../../../ActionsSection/ActionsSection.styled";
import {
  CircularBtn,
  ExpandIcon,
  InlineRow,
  LowerContainer,
  SwapWrapper,
  SwitchIcon,
  UpperContainer,
} from "./SwapBlock.styled";

const SwapBlock = () => {
  return (
    <SwapWrapper>
      <UpperContainer>
        <ActionTitle>Swap</ActionTitle>
        <LowerContainer>
          <InlineRow>
            <ActionTitle>0.00</ActionTitle>
            <ExpandIcon />
            <ActionTitle>ETH</ActionTitle>
          </InlineRow>
          <InlineRow className="smallText">
            <span>$0.00</span>
            <span>Balance: 0</span>
          </InlineRow>
        </LowerContainer>
      </UpperContainer>
      <CircularBtn>
        <SwitchIcon />
      </CircularBtn>
      <LowerContainer>
        <InlineRow>
          <ActionTitle>0.00</ActionTitle>
          <ExpandIcon />
          <ActionTitle>BTC</ActionTitle>
        </InlineRow>
        <InlineRow className="smallText">
          <span>$0.00</span>
          <span>Balance: 0</span>
        </InlineRow>
      </LowerContainer>
      <PrimaryButton style={{ margin: "auto 0", marginBottom: "0" }}>
        Buy
      </PrimaryButton>
    </SwapWrapper>
  );
};

export default SwapBlock;
