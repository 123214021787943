import { IActionClaims } from "../interfaces/IClaims";

export interface IMockActionClaims extends IActionClaims {
  description: string;
}

export const mockActionList: IMockActionClaims[] = [
  {
    actionName: "Deposit",
    actionTopic: "",
    description: `Secure your financial future by making a deposit and unlocking a world of possibilities for managing your funds and growing your wealth.`,
    smartContractFunction: "",
    claimTopics: [],
  },
  {
    actionName: "Withdraw",
    actionTopic: "",
    smartContractFunction: "",
    description: `Effortlessly access your funds and enjoy complete financial freedom with seamless withdrawal options designed to fit your unique needs and preferences.`,
    claimTopics: [],
  },
  {
    actionName: "Vote",
    actionTopic: "",
    smartContractFunction: "",
    description: `Make your voice heard and influence the future of your community by participating in the voting process and shaping the direction of important decisions.`,
    claimTopics: [],
  },
  {
    actionName: "Pools",
    actionTopic: "",
    smartContractFunction: "",
    description: `Join a thriving community of investors and maximize your returns by pooling your resources and accessing a diverse range of investment opportunities.`,

    claimTopics: [],
  },
  {
    actionName: "Farm",
    actionTopic: "",
    smartContractFunction: "",
    description: `Grow your wealth and earn passive income by farming a variety of high-yield crops and accessing exclusive rewards and incentives.`,
    claimTopics: [],
  },
  {
    actionName: "Vesting",
    actionTopic: "",
    smartContractFunction: "",
    description: `Secure your financial future and protect your investments by vesting your assets over time and enjoying long-term stability and growth.`,
    claimTopics: [],
  },
];

export const clientConfig = {
  id: 0,
  api_key: 0,
  name: "SuperExchange",
  phoneNumber: "+1 000 000 0000",
  email: "info@superexchange.com",
  logo: "",
  mockActionList,
};
