import React, { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import { StyledOutlinedButton } from "./OutlinedButton.styled";

type ButtonProps<T extends ElementType> = {
  renderAs?: keyof JSX.IntrinsicElements;
  address?: string;
  children?: ReactNode;
} & ComponentPropsWithoutRef<T>;

const OutlinedButton = <T extends ElementType = "button">({
  renderAs,
  children,
  address,
  ...rest
}: ButtonProps<T>) => {
  return (
    <StyledOutlinedButton as={renderAs} address={address} {...rest}>
      {children}
    </StyledOutlinedButton>
  );
};

export default OutlinedButton;
