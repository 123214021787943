import styled from "styled-components";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
} from "../../../../styles/variables";
import { UnstyledButton } from "../UnstyledButton.styled";

interface Props {
  disabled?: boolean;
  width?: string;
  withArrow?: boolean;
  isLoading?: boolean;
}

export const StyledButton = styled(UnstyledButton)<Props>`
  position: absolute;
  border: none;
  background: none;
  outline: none;
  color: ${({ theme }) => theme.colors.buttons.ghost.color};
  font-weight: ${FONT_WEIGHTS.medium};
  font-size: ${FONT_SIZES.small};
  top: 0;
  right: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.buttons.ghost.hoverColor};
  }
`;
