import React, { useRef } from "react";
import { PortalTarget } from "../../../interfaces/enums/PortalEnums";
import { IModalProps } from "../../../interfaces/IModal";
import { Portal } from "../Portal/Portal";

import { Overlay, StyledModal } from "./Modal.styled";

export const Modal = ({
  isOpen,
  handleClose,
  children,
}: IModalProps) => {
  const outsideRef = useRef(null);

  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === outsideRef.current) {
      handleClose();
    }
  };

  return isOpen ? (
    <Portal target={PortalTarget.MODAL}>
      <Overlay>
        <StyledModal ref={outsideRef} onClick={closeModal}>
          {children}
        </StyledModal>
      </Overlay>
    </Portal>
  ) : null;
};
