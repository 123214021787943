import styled, { createGlobalStyle } from "styled-components";
import { BORDER_RADII, DEMO_PAGE_FONT_FAMILY } from "./variables";

const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: ${DEMO_PAGE_FONT_FAMILY};
  }

  body {
    background-color: ${({ theme }) => theme.colors.background}; 
  }

  html, body {
    overflow: unset;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  body::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} 
  button, a {
    cursor: pointer;
  }

  div {
    cursor: default;
  }

`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1512px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 2rem;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const BlockContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.blockItem};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: ${BORDER_RADII.large};
  margin: 0;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

export default GlobalStyle;
