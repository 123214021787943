import {
  PENDING_DISCLAIMER,
  REJECTED_DISCLAIMER,
} from "../../../constants/disclaimers";
import { ResultStatus } from "../../../interfaces/enums/ResultStatusEnums";
import { IResultStatusProps } from "../../../interfaces/ICommonProps";
import {
  InfoIcon,
  InfoIconContainer,
  InfoMessage,
  StyledResultIndicator,
} from "./ResultIndicator.styled";

export const ResultIndicator = ({ status }: IResultStatusProps) => {
  let message =
    status === ResultStatus.PENDING ? PENDING_DISCLAIMER : REJECTED_DISCLAIMER;
  let infoIconContainer = null;

  if (status === ResultStatus.UNKNOWN) {
    return null;
  }

  if (status === ResultStatus.PENDING || status === ResultStatus.REJECTED) {
    infoIconContainer = (
      <InfoIconContainer>
        <InfoIcon />
        <InfoMessage status={status}>
          {status === ResultStatus.PENDING && (
            <span>Approximately Transaction fee: 0.01 ETH</span>
          )}
          {message}
        </InfoMessage>
      </InfoIconContainer>
    );
  }

  return (
    <StyledResultIndicator status={status}>
      {status}
      {infoIconContainer}
    </StyledResultIndicator>
  );
};

export const WalletScoreIndicator = ({ status }: IResultStatusProps) => {
  if (status === ResultStatus.UNKNOWN) {
    return null;
  }
  return (
    <StyledResultIndicator status={status}>{status} risk</StyledResultIndicator>
  );
};
