export function formatDate(timestamp: any) {
  const timestampInMilliseconds = timestamp * 1000;
  const date = new Date(timestampInMilliseconds);
  //adding 2 months
  date.setMonth(date.getMonth() + 2);
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  const formattedDate = `${year}.${month}.${day}`;
  return formattedDate;
}
