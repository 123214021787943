import { useState } from "react";
import {
  ChainId,
  useNetwork,
  useNetworkMismatch,
  useSigner,
} from "@thirdweb-dev/react";
import {
  CHAIN_ID,
  GATEKEEPER_ADDRESS,
} from "../web3/constants/contractAddresses";
import GATEKEEPER_ABI from "../web3/abi/Gatekeeper.json";
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { BigNumber } from "ethers";
import { ERRORS } from "../interfaces/enums/ErrorEnums";
import { getErrorMessage } from "../utils/getErrorMessage";
const contractAddress = GATEKEEPER_ADDRESS;
export function useDeployIdentity() {
  const [, switchNetwork] = useNetwork();
  const isWrongNetwork = useNetworkMismatch();
  const signer = useSigner();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ERRORS | undefined>(undefined);

  const isEnoughBalance = (balance: BigNumber, fee: BigNumber) => {
    if (balance.gt(fee)) {
      return true;
    }
    return false;
  };

  const deploySelfIdentity = async () => {
    setError(undefined);
    try {
      if (!signer) {
        throw new Error("Signer is undefined");
      }
      if (isWrongNetwork && switchNetwork) {
        const { error } = await switchNetwork(CHAIN_ID);
        if (error) {
          throw new Error(ERRORS.WRONG_NETWORK);
        }
        window.location.reload();
        return;
      }

      setIsLoading(true);
      const sdk = ThirdwebSDK.fromSigner(signer);
      const balance = (await sdk.wallet.balance()).value;
      const contract = await sdk.getContract(contractAddress!, GATEKEEPER_ABI);
      const chargableType = (
        await contract.call("CHARGEABLE_TYPE_DEPLOY_IDENTITY")
      ).toNumber();

      const fee = await contract.call("getFee", [chargableType]);
      const isEnough = isEnoughBalance(balance, fee);
      if (!isEnough) {
        throw new Error(ERRORS.INSUFFICIENT_BALANCE);
      }

      const result = await contract.call("deploySelfIdentity", { value: fee });
      setIsLoading(false);
      console.log("identity deployed successfully", result);
    } catch (error) {
      console.log("error while deploying identity", error);
      const errorMessage = getErrorMessage(error);
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  return {
    isDeployLoading: isLoading,
    deployIdentity: deploySelfIdentity,
    deployError: error,
  };
}
