import { useQuery } from "@tanstack/react-query";
import { useAddress, useUser } from "@thirdweb-dev/react";
import { getActions, getClientClaims } from "../api";

export function useClaims() {
  const address = useAddress();
  const { isLoggedIn } = useUser();
  const {
    data: claims,
    isLoading: claimsIsLoading,
    isError: claimsisError,
    error: claimsError,
  } = useQuery({
    queryKey: ["clientClaims"],
    queryFn: getClientClaims,
    refetchOnMount: false,
    enabled: isLoggedIn && !!address,
  });

  const {
    data: actions,
    isLoading: actionsIsLoading,
    isError: actionsisError,
    error: actionsError,
  } = useQuery({
    queryKey: ["clientActions"],
    queryFn: getActions,
    refetchOnMount: false,
  });

  return {
    claims,
    actions,
    isLoading: claimsIsLoading || actionsIsLoading,
    isError: actionsisError || claimsisError,
    error: actionsError || claimsError,
  };
}
