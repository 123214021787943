import { useEffect } from "react";
import { useAddress, useMetamask } from "@thirdweb-dev/react";
import { IModalContentProps } from "../../../interfaces/IModalContent";
import {
  ConnectWalletModalWrapper,
  MetamaskBtn,
  Title,
} from "./ConnectWalletModal.styled";
import MetamaskLogo from "../../../assets/Metamask.svg";

const ConnectWalletModal = ({ handleClose }: IModalContentProps) => {
  const connectWithMetamask = useMetamask();
  const address = useAddress();

  useEffect(() => {
    address && handleClose();
  }, [address]);

  return (
    <ConnectWalletModalWrapper>
      <Title>Connect Wallet</Title>
      <MetamaskBtn onClick={connectWithMetamask}>
        <img src={MetamaskLogo} alt="Metamask button" />
      </MetamaskBtn>
    </ConnectWalletModalWrapper>
  );
};

export default ConnectWalletModal;
