import GraphBlock from "./components/GraphBlock/GraphBlock";
import SwapBlock from "./components/SwapBlock/SwapBlock";
import { ExchangeSectionContainer } from "./ExchangeSection.styled";

const ExchangeSection = () => {
  return (
    <ExchangeSectionContainer>
      <GraphBlock />
      <SwapBlock />
    </ExchangeSectionContainer>
  );
};

export default ExchangeSection;
