import { useEffect, useState } from "react";
import {
  IN_REVIEW_TEXT,
  IN_REVIEW_TEXT_WALLET,
  REDIRECT_TEXT,
  RESTART_TEXT,
  VERIFY_RESTART_TEXT,
} from "../../../constants/blockText";
import { useSaveToBlockchain } from "../../../hooks/useSaveToBlockchain";
import { useStartClaimVerification } from "../../../hooks/useStartClaimVerification";
import {
  ClaimTopic,
  ClaimTopicId,
  ResultStatus,
  RiskLevel,
} from "../../../interfaces/enums/ResultStatusEnums";
import { IClaim } from "../../../interfaces/IClaims";
import { processClaim } from "../../../utils/processClaim";
import PrimaryButton from "../../Common/Buttons/PrimaryButton/PrimaryButton";
import {
  ResultIndicator,
  WalletScoreIndicator,
} from "../ResultIndicator/ResultIndicator";
import VerifyButton from "../VerifyButton/VerifyButton";
import {
  BlockLabel,
  BlockTitle,
  DateLabel,
  InfoText,
  InlineRow,
  ResultsWrapper,
  TopSection,
  VerifyBlockContainer,
} from "./VerifyBlock.styled";
import { formatDate } from "../../../utils/formatDate";
import { ERRORS } from "../../../interfaces/enums/ErrorEnums";
import TextButton from "../../Common/Buttons/TextButton/TextButton";

export interface IVerifyProps {
  setErrorMessage: React.Dispatch<React.SetStateAction<ERRORS | undefined>>;
  claimTopicId: ClaimTopicId;
  claimTopic: ClaimTopic;
  userClaim: IClaim | undefined;
  identity: string;
}

const VerifyBlock = ({
  setErrorMessage,
  claimTopicId,
  claimTopic,
  userClaim,
  identity,
}: IVerifyProps) => {
  const { saveDataToBlockchain, isSaveLoading, saveError } =
    useSaveToBlockchain();
  const { startClaimVerification, isLoading: isStartLoading } =
    useStartClaimVerification();

  const [restartButton, setRestartButton] = useState(false);
  const handleRestartVerification = () => {
    setRestartButton(!restartButton);
  };

  const handleSave = async () => {
    await saveDataToBlockchain(
      identity,
      claimTopicId,
      userClaim?.signature,
      userClaim?.claimData,
      userClaim?.id!
    );
  };

  const handleStart = async () => {
    await startClaimVerification({
      claimId: claimTopicId,
    });
  };
  const handleTryAgain = async () => {
    await startClaimVerification({
      claimId: claimTopicId,
      retryClaimId: userClaim?.id,
    });
  };

  useEffect(() => {
    setErrorMessage(saveError);
  }, [saveError]);

  return (
    <VerifyBlockContainer>
      <TopSection>
        <BlockLabel>Verify your</BlockLabel>
        <ResultsWrapper>
          {claimTopicId === ClaimTopicId.WALLET && (
            <WalletScoreIndicator
              status={userClaim?.relatedData?.riskLevel || RiskLevel.UNKNOWN}
            />
          )}

          {processClaim(userClaim) !== ResultStatus.CREATED && (
            <>
              {processClaim(userClaim) == ResultStatus.PENDING &&
                claimTopicId === ClaimTopicId.ACCOUNT && (
                  <ResultIndicator status={ResultStatus.PASSED} />
                )}
              <ResultIndicator status={processClaim(userClaim)} />{" "}
            </>
          )}
        </ResultsWrapper>
      </TopSection>
      <BlockTitle>
        {claimTopicId === ClaimTopicId.WALLET ? "Wallet" : "Account"}
      </BlockTitle>
      {renderVerifyBlockContent({
        userClaim,
        claimTopicId,
        handleSave,
        handleStart,
        handleTryAgain,
        isSaveLoading,
        isStartLoading,
        restartButton,
        handleRestartVerification,
      })}
    </VerifyBlockContainer>
  );
};

export default VerifyBlock;

interface IVerifyContentProps
  extends Omit<IVerifyProps, "identity" | "claimTopic" | "setErrorMessage"> {
  handleSave: () => Promise<void>;
  handleStart: () => Promise<void>;
  handleTryAgain: () => Promise<void>;
  isSaveLoading: boolean;
  isStartLoading: boolean;
  handleRestartVerification: () => void;
  restartButton: boolean;
}

const renderVerifyBlockContent = ({
  userClaim,
  claimTopicId,
  handleSave,
  handleStart,
  handleTryAgain,
  isSaveLoading,
  isStartLoading,
  restartButton,
  handleRestartVerification,
}: IVerifyContentProps) => {
  switch (processClaim(userClaim)) {
    case ResultStatus.CREATED:
      return (
        claimTopicId === ClaimTopicId.ACCOUNT && (
          <>
            <InfoText>{REDIRECT_TEXT}</InfoText>
            <PrimaryButton
              renderAs={"a"}
              href={userClaim?.requiredUserActions[0].actionData}
              target="_blank"
              style={{ marginTop: "1rem", textDecoration: "none" }}
            >
              Verify
            </PrimaryButton>
          </>
        )
      );
    case ResultStatus.STARTED:
      return (
        <>
          {!restartButton ? (
            <>
              <InfoText>{RESTART_TEXT}</InfoText>
              <TextButton
                style={{
                  position: "relative",
                  paddingTop: "0.75rem",
                  margin: "0 auto",
                }}
                onClick={handleRestartVerification}
              >
                Restart
              </TextButton>
            </>
          ) : (
            <>
              <InfoText>{VERIFY_RESTART_TEXT}</InfoText>
              <InlineRow>
                <TextButton
                  style={{
                    position: "relative",
                    paddingTop: "0.75rem",
                    margin: "0 auto",
                  }}
                  onClick={handleTryAgain}
                  disabled={isStartLoading}
                  isLoading={isStartLoading}
                >
                  Confirm
                </TextButton>
                <TextButton
                  style={{
                    position: "relative",
                    paddingTop: "0.75rem",
                    margin: "0 auto",
                  }}
                  onClick={handleRestartVerification}
                >
                  Go Back
                </TextButton>
              </InlineRow>
            </>
          )}
        </>
      );
    case ResultStatus.VERIFIED:
      return (
        <DateLabel>
          {userClaim?.relatedData.timestamp &&
            `Valid until: ${formatDate(userClaim?.relatedData.timestamp)}`}
        </DateLabel>
      );
    case ResultStatus.IN_REVIEW:
      return (
        <InfoText>
          {claimTopicId === ClaimTopicId.ACCOUNT
            ? `${IN_REVIEW_TEXT}`
            : claimTopicId === ClaimTopicId.WALLET
            ? `${IN_REVIEW_TEXT_WALLET}`
            : ""}
        </InfoText>
      );
    default:
      return (
        <VerifyButton
          status={processClaim(userClaim)}
          handleSave={handleSave}
          handleStart={handleStart}
          handleTryAgain={handleTryAgain}
          isSaveLoading={isSaveLoading}
          isStartLoading={isStartLoading}
        />
      );
  }
};
