import styled from "styled-components";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
} from "../../../../../styles/variables";

export const GraphBlockWrapper = styled.div`
  height: 100%;
  flex: 2.2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex: 1;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .date {
    font-size: ${FONT_SIZES.extraSmall};
    color: ${({ theme }) => theme.colors.text.label};
    font-weight: ${FONT_WEIGHTS.medium};
  }
`;

export const ValueIndicator = styled.div`
  font-size: ${FONT_SIZES.small};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${FONT_WEIGHTS.bold};

  .value {
    font-size: ${FONT_SIZES.large};
  }

  .currency {
    margin-left: 0.25rem;
  }

  .percentage {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.text.accents.secondary};
  }
`;

export const ScaleSelector = styled.div`
  display: flex;
  width: 16.625rem;
  height: 2.438rem;
  border-radius: ${BORDER_RADII.medium};
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const ScaleItem = styled.button`
  flex: 1;
  outline: none;
  border: none;
  border-radius: inherit;
  background-color: inherit;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.small};

  &:focus,
  &.selected {
    background-color: ${({ theme }) => theme.colors.buttons.primary.background};
    color: ${({ theme }) => theme.colors.buttons.primary.color};
  }
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 3;
  gap: 1rem;

  span {
    color: ${({ theme }) => theme.colors.text.label};
    font-weight: ${FONT_WEIGHTS.medium};
    font-size: ${FONT_SIZES.extraSmall};
    white-space: nowrap;
  }

  & > img {
    height: 100%;
    width: 100%;
  }
`;

export const CurrencyColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;

  & > span:last-child {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${FONT_WEIGHTS.bold};
  }
`;

export const GraphWrapper = styled.div`
  justify-content: end;
  display: flex;
  flex-direction: column;
  height: 10rem;
  width: 100%;
`;

export const GraphInline = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  height: 10rem;

  & > img {
    height: 100%;
    width: 90%;
  }
`;

export const DashedLine = styled.div`
  border: 1px dashed ${({ theme }) => theme.colors.text.label};
  width: 90%;
`;

export const InlineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;
  padding-top: 0.75rem;
  width: 90%;
`;
