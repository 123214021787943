import { useAddress, useLogin, useUser } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import {
  Nav,
  ClientSectionWrapper,
  NavbarInnerBlockContainer,
  ConnectGatekeeperWrapper,
} from "./Navbar.styled";
import { clientConfig } from "../../../data/mockData";
import { Container } from "../../../styles/globalStyles";
import clientLogo from "../../../assets/Logo1.svg";
import gatekeeperLogo from "../../../assets/Logo 2.svg";
import gatekeeperHoverLogo from "../../../assets/Logo_hover_toggle.svg";
import { useLogoutOverwritten } from "../../../hooks/useLogout";
import { ModalType } from "../../../interfaces/enums/PortalEnums";
import PrimaryButton from "../../Common/Buttons/PrimaryButton/PrimaryButton";
import OutlinedButton from "../../Common/Buttons/OutlinedButton/OutlinedButton";

interface INavbarProps {
  openModal: (modalType: ModalType) => void;
  closeModal: () => void;
}

function Navbar({ openModal, closeModal }: INavbarProps) {
  const address = useAddress();
  const { login } = useLogin();
  const { user, isLoggedIn } = useUser();
  const { logout } = useLogoutOverwritten();
  const [logo, setLogo] = useState(gatekeeperLogo);

  const handleLogin = async () => {
    await login();
    openModal(ModalType.Widget);
  };

  useEffect(() => {
    !isLoggedIn && closeModal();
  }, [isLoggedIn]);

  useEffect(() => {
    if (address && user?.address) {
      if (address !== user?.address) {
        closeModal();
        logout();
      }
    }
  }, [address, user?.address, logout]);

  return (
    <Nav>
      <Container>
        <NavbarInnerBlockContainer>
          <ClientSectionWrapper>
            <img src={clientLogo} />
            <span>{clientConfig.name}</span>
          </ClientSectionWrapper>

          {address ? (
            <ConnectGatekeeperWrapper>
              <OutlinedButton address={address} />
              {isLoggedIn ? (
                <PrimaryButton
                  style={{ width: "auto", position: "relative" }}
                  disabled={!address}
                  onClick={() => openModal(ModalType.Widget)}
                  onMouseEnter={() => setLogo(gatekeeperHoverLogo)}
                  onMouseLeave={() => setLogo(gatekeeperLogo)}
                >
                  <img src={logo} alt="Gatekeeper logo" />
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  style={{ width: "auto" }}
                  disabled={!address}
                  onClick={handleLogin}
                  onMouseEnter={() => setLogo(gatekeeperHoverLogo)}
                  onMouseLeave={() => setLogo(gatekeeperLogo)}
                >
                  <img src={logo} alt="Gatekeeper logo" />
                </PrimaryButton>
              )}
            </ConnectGatekeeperWrapper>
          ) : (
            <PrimaryButton
              style={{ width: "auto" }}
              onClick={() => openModal(ModalType.ConnectWallet)}
            >
              Connect Wallet
            </PrimaryButton>
          )}
        </NavbarInnerBlockContainer>
      </Container>
    </Nav>
  );
}

export default Navbar;
