import axios from "axios";

export const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const API_KEY =
  "gXqHPh9swFAnMUNAGat5f8byGZErZqf56rXQirZAsfKHUYftdvMumR5JLCpbGBw3R5iP7hBubnBf5nZJcniEDzEKLxAWeJzouoGF3osy4NRjoZn2yLw7qRANxEhpFCZs";

export const api = axios.create({
  baseURL: API_ENDPOINT,
  headers: { "x-micapass-api-key": API_KEY },
});
