import { IDENTITY_TEXT } from "../../../constants/blockText";
import { ERRORS } from "../../../interfaces/enums/ErrorEnums";
import PrimaryButton from "../../Common/Buttons/PrimaryButton/PrimaryButton";
import { VerifyBlocksSection } from "../GatekeeperModal/GatekeeperModal.styled";
import {
  BlockLabel,
  BlockTitle,
  TopSection,
  VerifyBlockContainer,
} from "../VerifyBlock/VerifyBlock.styled";
import { InfoTextBolder } from "./DeployIdentityBlock.styled";

interface IDeployIdentityBlockProps {
  isLoading: boolean;
  handleClick: () => Promise<void>;
}

const DeployIdentityBlock = ({
  isLoading,
  handleClick,
}: IDeployIdentityBlockProps) => {
  return (
    <VerifyBlocksSection>
      <VerifyBlockContainer>
        <TopSection>
          <BlockLabel>Save your</BlockLabel>
        </TopSection>
        <BlockTitle>Identity</BlockTitle>
        <InfoTextBolder>{IDENTITY_TEXT}</InfoTextBolder>
        <PrimaryButton
          withArrow={!isLoading}
          style={{ marginTop: "1rem" }}
          onClick={handleClick}
          disabled={isLoading}
          isLoading={isLoading}
        >
          Save
        </PrimaryButton>
      </VerifyBlockContainer>
    </VerifyBlocksSection>
  );
};

export default DeployIdentityBlock;
