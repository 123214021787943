import { DefaultTheme } from "styled-components";
import {
  BORDER_RADII,
  BOX_SHADOWS,
  FONT_SIZES,
  DEMO_PAGE_COLORS,
  DEMO_PAGE_FONT_FAMILY,
} from "../variables";

export const demoPageTheme: DefaultTheme = {
  colors: {
    primary: DEMO_PAGE_COLORS.primary,
    secondary: DEMO_PAGE_COLORS.secondary,
    background: DEMO_PAGE_COLORS.background,
    blockItem: DEMO_PAGE_COLORS.blockItem,
    footer: DEMO_PAGE_COLORS.primary,
    text: {
      footer: DEMO_PAGE_COLORS.secondary,
      title: DEMO_PAGE_COLORS.primary,
      description: DEMO_PAGE_COLORS.textGrey,
      label: DEMO_PAGE_COLORS.accentLighter,
      accents: {
        primary: DEMO_PAGE_COLORS.accentDarker,
        secondary: DEMO_PAGE_COLORS.accentRed,
      },
    },
    buttons: {
      primary: {
        background: DEMO_PAGE_COLORS.primary,
        color: DEMO_PAGE_COLORS.btnLabel,
        hoverBackground: DEMO_PAGE_COLORS.btnHovered,
        disabledBackground: DEMO_PAGE_COLORS.btnDisabled,
      },
      ghost: {
        color: DEMO_PAGE_COLORS.primary,
        hoverColor: DEMO_PAGE_COLORS.btnHovered,
        disabledColor: DEMO_PAGE_COLORS.btnDisabled,
      },
      outlined: {
        background: DEMO_PAGE_COLORS.secondary,
        color: DEMO_PAGE_COLORS.primary,
      },
    },
  },
  fontFamily: DEMO_PAGE_FONT_FAMILY,
  otherColors: {
    ...DEMO_PAGE_COLORS,
  },
  fontSizes: {
    ...FONT_SIZES,
  },
  borderRadii: {
    ...BORDER_RADII,
  },
  boxShadow: BOX_SHADOWS.greyShadow
};
