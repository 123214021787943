import { useState } from "react";
import { useNetwork, useNetworkMismatch, useSigner } from "@thirdweb-dev/react";
import { CHAIN_ID, CLIENT_ADDRESS } from "../web3/constants/contractAddresses";
import CLIENT_ABI from "../web3/abi/Client.json";
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { ethers } from "ethers";
import { toast } from "react-toastify";

const contractAddress = CLIENT_ADDRESS;

export function useClientActions() {
  const [, switchNetwork] = useNetwork();
  const isWrongNetwork = useNetworkMismatch();
  const signer = useSigner();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const performContractAction = async (action: "deposit" | "withdraw") => {
    try {
      if (!signer) {
        throw new Error("Signer is undefined");
      }
      if (isWrongNetwork && switchNetwork) {
        const { error } = await switchNetwork(CHAIN_ID);
        if (error) {
          throw new Error("Wrong network");
        }
        window.location.reload();
        return;
      }

      setIsLoading(true);
      const sdk = ThirdwebSDK.fromSigner(signer);
      const contract = await sdk.getContract(contractAddress!, CLIENT_ABI);
      let result;
      if (action === "deposit") {
        result = await contract.call("deposit", {
          value: ethers.utils.parseEther("0.01"),
        });
      } else if (action === "withdraw") {
        result = await contract.call("withdraw");
      }
      setIsLoading(false);
      toast.success("Transaction was successfull");
      console.log(`${action}ed`, result);
    } catch (e: any) {
      console.log(`Error while ${action}ing`, e);
      toast.error(e.reason);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    deposit: () => performContractAction("deposit"),
    withdraw: () => performContractAction("withdraw"),
  };
}
