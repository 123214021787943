import { ActionStatus } from "../../../interfaces/enums/ActionStatusEnums";
import { IActionClaims, IUserClaimsList } from "../../../interfaces/IClaims";
import { ResultStatus } from "../../../interfaces/enums/ResultStatusEnums";
import { processClaim } from "../../../utils/processClaim";

interface IProps {
  action: IActionClaims;
  renderStatus: (status: ActionStatus) => React.ReactElement | null;
  userClaims: IUserClaimsList | undefined;
}

export function getActionStatus(
  action: IActionClaims,
  userClaims: IUserClaimsList | undefined
) {
  let allVerified = true;
  if (!userClaims) {
    return ActionStatus.DENIED;
  }
  for (const claimTopic of action.claimTopics) {
    const claim = userClaims.claims.find(
      (claim) => claim.claimTopic === claimTopic
    );
    if (!claim || processClaim(claim) !== ResultStatus.VERIFIED) {
      allVerified = false;
      break;
    }
  }
  if (allVerified) {
    return ActionStatus.ALLOWED;
  } else return ActionStatus.DENIED;
}

const ActionStatusComponent = ({
  action,
  userClaims,
  renderStatus,
}: IProps) => {
  const status = getActionStatus(action, userClaims);
  return renderStatus(status);
};

export default ActionStatusComponent;
