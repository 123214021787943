import styled from "styled-components";
import { BlockContainer } from "../../../styles/globalStyles";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
} from "../../../styles/variables";

export const ConnectWalletModalWrapper = styled(BlockContainer)`
  padding: 2rem;
  width: 29.125rem;
  height: 27.625rem;
  gap: 2rem;
`;

export const Title = styled.span`
  font-size: ${FONT_SIZES.large};
  font-weight: ${FONT_WEIGHTS.bold};
  color: ${({ theme }) => theme.colors.text.title};
`;

export const MetamaskBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 3px solid #e27625;
  height: 3.875rem;
  background-color: inherit;
  border-radius: ${BORDER_RADII.large};
`;
