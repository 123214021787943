import styled from "styled-components";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
} from "../../../../styles/variables";
import { UnstyledButton } from "../UnstyledButton.styled";

interface Props {
  disabled?: boolean;
  width?: string;
  withArrow?: boolean;
  isLoading?: boolean;
}

export const StyledButton = styled(UnstyledButton)<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${BORDER_RADII.medium};
  height: 2.438rem;
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.small};
  padding: 0.75rem 1.5rem;
  gap: 0.813rem;
  width: ${({ width }) => (width ? width : "100%")};
  cursor: ${({ disabled, isLoading }) =>
    disabled ? "not-allowed" : isLoading ? "wait" : "pointer"};

  color: ${({ theme }) => theme.colors.buttons.primary.color};
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.buttons.primary.disabledBackground
      : theme.colors.buttons.primary.background};

  &:hover,
  &.active {
    background-color: ${({ theme }) =>
      theme.colors.buttons.primary.hoverBackground};

    ${({ withArrow, theme, disabled }) =>
      withArrow &&
      !!!disabled &&
      `
   &:after {
      content: "";
      border: solid ${theme.colors.buttons.primary.color};
      border-width: 0 0.15rem 0.15rem 0;
      padding: 0.188rem;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  `}
  }
`;
