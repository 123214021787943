export const STATUS_COLORS = {
  approved: "#62FAAA",
  denied: "#FA6266",
  pending: "#FAF662",
};

export const WIDGET_COLORS = {
  //main colors
  primary: "#6266FA",
  secondaryGrey: "#A7A9B2",
  background: "#F3F3F5",
  blockItem: "#FEFEFE",
  //text
  textBlack: "#030415",
  //buttons
  btnLabel: "#FEFEFF",
  btnHovered: "#7C88FE",
  //ghost and outlined buttons
  textBtnHovered: "#707481",
  ...STATUS_COLORS,
};

export const DEMO_PAGE_COLORS = {
  //main colors
  primary: "#282F65",
  secondary: "#EBEDFF",
  background: "#EEF0F5",
  blockItem: "#FEFEFE",
  //text
  textGrey: "#7B7B7B",
  accentDarker: "#AEAEAE",
  accentLighter: "#A7A9B2",
  accentRed: "#F05559",
  //button
  btnLabel: "#FEFEFF",
  btnHovered: "#606993",
  btnDisabled: "#B8BDD0",
  ...STATUS_COLORS,
};

export const BORDER_RADII = {
  circle: "100%",
  small: "0.125rem", //2px
  medium: "0.5rem", //8px
  large: "0.75rem", //12px
  extraLarge: "1rem", //16px
};

export const BOX_SHADOWS = {
  greyShadow: "0px 4px 10px 0px #D9D9D9",
};

export const FONT_SIZES = {
  extraSmall: "10px",
  small: "12px",
  medium: "14px",
  large: "24px",
  extraLarge: "32px",
};

export const FONT_WEIGHTS = {
  light: "300",
  regular: "400",
  medium: "500",
  bold: "700",
};

export const DEMO_PAGE_FONT_FAMILY = "Montserrat, sans-serif";
export const WIDGET_FONT_FAMILY = "Inter, sans-serif";
