import React, { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import { Spinner } from "../../Spinner/Spinner";
import { StyledButton } from "./PrimaryButton.styled";

type ButtonProps<T extends ElementType> = {
  renderAs?: keyof JSX.IntrinsicElements;
  disabled?: boolean;
  isLoading?: boolean;
  withArrow?: boolean;
  href?: any;
  target?: any;
  children: ReactNode;
} & ComponentPropsWithoutRef<T>;

const PrimaryButton = <T extends ElementType = "button">({
  renderAs,
  children,
  withArrow,
  disabled = false,
  isLoading = false,
  onClick,
  ...rest
}: ButtonProps<T>) => {
  return (
    <StyledButton
      as={renderAs}
      withArrow={withArrow}
      isLoading={isLoading}
      disabled={disabled}
      onClick={disabled || isLoading ? undefined : onClick}
      {...rest}
    >
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>{children}</>
      )}
    </StyledButton>
  );
};

export default PrimaryButton;
