import { ResultStatus } from "../../../interfaces/enums/ResultStatusEnums";
import { IResultStatusProps } from "../../../interfaces/ICommonProps";
import PrimaryButton from "../../Common/Buttons/PrimaryButton/PrimaryButton";

interface IVerifyBtnProp extends IResultStatusProps {
  handleSave: () => Promise<void>;
  isSaveLoading: boolean;
  handleStart: () => Promise<void>;
  handleTryAgain: () => Promise<void>;
  isStartLoading: boolean;
}
const VerifyButton = ({
  status,
  handleSave,
  handleStart,
  handleTryAgain,
  isStartLoading,
  isSaveLoading,
}: IVerifyBtnProp) => {
  if (status === ResultStatus.PENDING) {
    return (
      <PrimaryButton
        withArrow={!isSaveLoading}
        disabled={isSaveLoading}
        isLoading={isSaveLoading}
        onClick={handleSave}
        style={{ marginTop: "1rem" }}
      >
        Save information to Blockchain
      </PrimaryButton>
    );
  } else if (status === ResultStatus.REJECTED) {
    return (
      <PrimaryButton
        withArrow={!isStartLoading}
        style={{ marginTop: "1rem" }}
        onClick={handleTryAgain}
        disabled={isStartLoading}
        isLoading={isStartLoading}
      >
        Try again
      </PrimaryButton>
    );
  } else if (
    status === ResultStatus.VERIFIED ||
    status === ResultStatus.IN_REVIEW
  ) {
    return null;
  } else {
    return (
      <PrimaryButton
        withArrow={!isStartLoading}
        style={{ marginTop: "1rem" }}
        onClick={handleStart}
        disabled={isStartLoading}
        isLoading={isStartLoading}
      >
        Start
      </PrimaryButton>
    );
  }
};

export default VerifyButton;
