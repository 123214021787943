import { DefaultTheme } from "styled-components";
import {
  BORDER_RADII,
  BOX_SHADOWS,
  FONT_SIZES,
  WIDGET_COLORS,
  WIDGET_FONT_FAMILY,
} from "../variables";

export const widgetTheme: DefaultTheme = {
  colors: {
    primary: WIDGET_COLORS.primary,
    background: WIDGET_COLORS.background,
    blockItem: WIDGET_COLORS.blockItem,
    text: {
      title: WIDGET_COLORS.primary,
      description: WIDGET_COLORS.textBlack,
      label: WIDGET_COLORS.textBlack,
      accents: { primary: WIDGET_COLORS.secondaryGrey },
    },
    buttons: {
      primary: {
        background: WIDGET_COLORS.primary,
        color: WIDGET_COLORS.btnLabel,
        hoverBackground: WIDGET_COLORS.btnHovered,
        disabledBackground: WIDGET_COLORS.primary,
      },
      ghost: {
        color: WIDGET_COLORS.secondaryGrey,
        hoverColor: WIDGET_COLORS.textBtnHovered,
      },
      outlined: {
        background: WIDGET_COLORS.blockItem,
        color: WIDGET_COLORS.secondaryGrey,
        hoverColor: WIDGET_COLORS.textBtnHovered,
      },
    },
  },
  fontFamily: WIDGET_FONT_FAMILY,
  otherColors: {
    ...WIDGET_COLORS,
  },
  fontSizes: {
    ...FONT_SIZES,
  },
  borderRadii: {
    ...BORDER_RADII,
  },
  boxShadow: BOX_SHADOWS.greyShadow,
};
