import { QueryKey } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_ENDPOINT } from "../api/apiConfig";

const TW_CACHE_KEY_PREFIX = "tw-cache";

function enforceCachePrefix(input: QueryKey): QueryKey {
  return [
    TW_CACHE_KEY_PREFIX,
    ...input.filter((i) => typeof i !== "string" || i !== TW_CACHE_KEY_PREFIX),
  ];
}

const cacheKeys = {
  auth: {
    user: () => enforceCachePrefix(["user"]),
  },
};

export function useLogoutOverwritten() {
  const queryClient = useQueryClient();

  const logout = useMutation({
    mutationFn: async () => {
      await fetch(`${API_ENDPOINT}/auth/logout`, {
        method: "POST",
        credentials: "include",
      });

      queryClient.invalidateQueries(cacheKeys.auth.user());
    },
  });

  return { logout: logout.mutateAsync, isLoading: logout.isLoading };
}
