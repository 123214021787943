import styled from "styled-components";
import {
  ResultStatus,
  RiskLevel,
} from "../../../interfaces/enums/ResultStatusEnums";
import { IResultStatusProps } from "../../../interfaces/ICommonProps";
import { BiInfoCircle } from "react-icons/bi";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
  STATUS_COLORS,
} from "../../../styles/variables";

export const StyledResultIndicator = styled.div<IResultStatusProps>`
  justify-content: space-between;
  justify-items: center;
  gap: 0.25rem;
  display: flex;
  width: fit-content;
  padding: 0.125rem 0.5rem;
  border-radius: ${BORDER_RADII.medium};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.extraSmall};
  color: ${({ theme }) => theme.colors.text.label};

  ${({ status }) => {
    switch (status) {
      case ResultStatus.IN_REVIEW:
      case ResultStatus.PENDING:
        case ResultStatus.STARTED: {
        return `
                    background-color: ${STATUS_COLORS.pending};
                `;
      }
      case ResultStatus.VERIFIED:
      case ResultStatus.PASSED:
      case RiskLevel.LOW:
      case RiskLevel.MEDIUM: {
        return `
                    background-color: ${STATUS_COLORS.approved};
                `;
      }
      case ResultStatus.REJECTED:
      case RiskLevel.HIGH:
      case RiskLevel.SEVERE: {
        return `
                    background-color: ${STATUS_COLORS.denied};
                `;
      }
      default:
        return;
    }
  }}
`;

export const InfoIconContainer = styled.span`
  position: relative;
`;

export const InfoIcon = styled(BiInfoCircle)`
  width: 100%;
  height: 100%;
  font-weight: ${FONT_WEIGHTS.bold};
`;

export const InfoMessage = styled.div<IResultStatusProps>`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 18rem;
  height: fit-content;
  border-radius: ${BORDER_RADII.medium};
  top: calc(100% + 0.4rem);
  right: calc(100% - 1.2rem);
  gap: 0.25rem;
  padding: 0.5rem;
  position: absolute;
  background-color: ${({ status }) =>
    status === ResultStatus.PENDING
      ? `${STATUS_COLORS.pending}`
      : `${STATUS_COLORS.denied}`};
  text-align: left;
  font-size: ${FONT_SIZES.extraSmall};
  z-index: 1;
  white-space: pre-wrap;

  ${InfoIconContainer}:hover & {
    display: flex;
  }
`;
