import styled from "styled-components";
import { BlockContainer } from "../../../styles/globalStyles";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
} from "../../../styles/variables";

export const VerifyBlockContainer = styled(BlockContainer)`
  max-height: fit-content;
  width: 25rem;
  border-radius: ${BORDER_RADII.medium};
  padding: 0.75rem;
  box-shadow: none;
`;

export const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ResultsWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const BlockLabel = styled.span`
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.medium};
  color: ${({ theme }) => theme.colors.text.label};
`;

export const BlockTitle = styled.span`
  color: ${({ theme }) => theme.colors.text.title};
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.extraLarge};
`;

export const DateLabel = styled.span`
  font-size: ${FONT_SIZES.extraSmall};
  font-weight: ${FONT_WEIGHTS.medium};
  color: ${({ theme }) => theme.colors.text.accents.primary};
  background: none;
  padding-top: 0.25rem;
`;

export const InfoText = styled.p`
  font-size: ${FONT_SIZES.small};
  font-weight: ${FONT_WEIGHTS.regular};
  color: ${({ theme }) => theme.colors.text.description};
  width: 90%;
`;

export const InlineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
