import styled from "styled-components";
import { FONT_SIZES, FONT_WEIGHTS } from "../../../styles/variables";
import { VerifyBlockContainer } from "../VerifyBlock/VerifyBlock.styled";

export const InfoTextBolder = styled.p`
  margin-top: 0.75rem;
  font-size: ${FONT_SIZES.extraSmall};
  font-weight: ${FONT_WEIGHTS.medium};
  color: ${({ theme }) => theme.colors.text.description};
  width: 92%;
  margin-bottom: 1rem;
`;

