import styled from "styled-components";
import { BlockContainer } from "../../../../../styles/globalStyles";
import { RxCaretSort } from "react-icons/rx";
import { HiChevronDown } from "react-icons/hi";
import { BORDER_RADII, FONT_SIZES, FONT_WEIGHTS } from "../../../../../styles/variables";

export const SwapWrapper = styled.div`
  height: inherit;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

`;

export const LowerContainer = styled(BlockContainer)`
  height: 4.063rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 0.75rem 1rem;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: none;
`;

export const InlineRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.smallText {
    & > span {
      font-size: ${FONT_SIZES.extraSmall};
      color: ${({ theme }) => theme.colors.text.accents.primary};
      font-weight: ${FONT_WEIGHTS.medium};
    }
  }
`;

export const UpperContainer = styled(BlockContainer)`
  padding: 1rem 1rem 0 1rem;
  height: 7.625rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  justify-content: space-between;
  box-shadow: none;

  & > ${LowerContainer} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CircularBtn = styled.button`
  border-radius: ${BORDER_RADII.circle};
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
`;

export const SwitchIcon = styled(RxCaretSort)`
  color: ${({ theme }) => theme.colors.blockItem};
  width: 1.25rem;
  height: 1.25rem;
`;

export const ExpandIcon = styled(HiChevronDown)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${FONT_SIZES.large};
  margin: 0 auto;
  margin-right: 0;
  display: flex;
  cursor: pointer;
`;
