export enum ClaimTopic {
  ACCOUNT = "Gatekeeper.KYC",
  WALLET = "Gatekeeper.WalletScreening",
}

export enum ClaimTopicId {
  ACCOUNT = 1000001,
  WALLET = 1000002,
}

export enum ResultStatus {
  CREATED = "Created",
  STARTED = 'In Progress',
  IN_REVIEW = "In Review",
  PENDING = "Pending",
  VERIFIED = "Verified",
  REJECTED = "Rejected",
  UNKNOWN = "Unknown",
  PASSED = "KYC Approved",
}

export enum RiskLevel {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
  SEVERE = "Severe",
  UNKNOWN = "Unknown",
}
