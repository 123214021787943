export const IDENTITY_TEXT = `Save your identity means that you will create a unique digital identity for yourself using the technology of blockchain. This identity will be stored securely. When you make a transaction to Save your identity on the blockchain, a small fee will be charged to cover the cost of processing the transaction. The approximate transaction fee for this process is 0.01 ETH, although the fee may vary based on network congestion and transaction priority.`;

export const IN_REVIEW_TEXT = `Your account verification information is currently being reviewed. Please wait while we verify the information you have provided.`;

export const REDIRECT_TEXT = `Your account needs to  be verified by a third-party service. Please click "Verify" to start the verification process.`;

export const IN_REVIEW_TEXT_WALLET = `Please be advised that your wallet is currently under review. We kindly request your patience during this process, as it may take some time.`;

export const RESTART_TEXT = `You have started the verification process. In case You have closed the window - You have the ability to restart the verification process.`;

export const VERIFY_RESTART_TEXT = `Please check carefully if the verification process window is properly closed. If so, click 'Confirm' button. Otherwise, click 'Go Back' button.`;
