import {
  IActionsConfiguration,
  IClaimsConfiguration,
  IUserClaimsList,
  UserClaimRequest,
} from "../interfaces/IClaims";
import {
  ClaimTopic,
  ClaimTopicId,
} from "../interfaces/enums/ResultStatusEnums";
import { api } from "./apiConfig";

export const getActions = async (): Promise<IActionsConfiguration> => {
  const response = await api.get("/project/action-config", {
    withCredentials: true,
  });
  return response.data;
};

export const getClientClaims = async (): Promise<IClaimsConfiguration> => {
  const response = await api.get("/project/wanted-claims", {
    withCredentials: true,
  });
  return response.data;
};

export const getUserClaims = async (): Promise<IUserClaimsList> => {
  const response = await api.get("/claims", {
    withCredentials: true,
  });
  return response.data;
};

export const postUserClaim = async ({
  claimId,
  retryClaimId,
}: UserClaimRequest) => {
  return await api.post(
    "/claims",
    { claimId, retryClaimId },
    {
      withCredentials: true,
    }
  );
};
