import styled from "styled-components";
import {
  FONT_SIZES,
  FONT_WEIGHTS,
  WIDGET_FONT_FAMILY,
} from "../../../../styles/variables";
import { formatAddress } from "../../../../utils/formatAddress";
interface Props {
  address?: string;
}

export const StyledOutlinedButton = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  height: 1.438rem;
  background-color: ${({ theme }) => theme.colors.buttons.outlined.background};
  border: none;
  border-radius: 4px;
  padding: 0.25rem;
  font-family: ${WIDGET_FONT_FAMILY};
  &::after {
    content: "${({ address }) => address && formatAddress(address)}";
    color: ${({ theme }) => theme.colors.buttons.outlined.color};
    font-weight: ${FONT_WEIGHTS.medium};
    font-size: ${FONT_SIZES.small};
  }

  &:hover {
    &::after {
      content: "${(props) => props.address}";
      color: ${({ theme }) => theme.colors.buttons.outlined.hoverColor};
    }
  }
`;
