import { ERRORS } from "../interfaces/enums/ErrorEnums";

export const getErrorMessage = (error: any) => {
  if (error.message === ERRORS.INSUFFICIENT_BALANCE) {
    return ERRORS.INSUFFICIENT_BALANCE;
  } else if (error.message === ERRORS.WRONG_NETWORK) {
    return ERRORS.WRONG_NETWORK;
  } else if (error.reason === ERRORS.USER_REJECTED) {
    return undefined;
  } else {
    return ERRORS.UNKNOWN_ERROR;
  }
};
