import { useState } from "react";
import {
  CurrencyColumn,
  DashedLine,
  GraphBlockWrapper,
  GraphContainer,
  GraphInline,
  GraphWrapper,
  InfoWrapper,
  InlineRow,
  ScaleItem,
  ScaleSelector,
  TopSection,
  ValueIndicator,
} from "./GraphBlock.styled";
import LineGraph from "../../../../../assets/Vector.svg";
const GraphBlock = () => {
  const [active, setActive] = useState(1);

  return (
    <GraphBlockWrapper>
      <TopSection>
        <InfoWrapper>
          <ValueIndicator>
            <span className="value">1 500.58</span>
            <span className="currency">ETH</span>
            <span className="percentage">{`-14.620 (-3.54%)`}</span>
          </ValueIndicator>
          <span className="date">2023.04.22 at 2:25 PM</span>
        </InfoWrapper>
        <ScaleSelector>
          <ScaleItem
            className={active === 1 ? "selected" : ""}
            onClick={() => setActive(1)}
          >
            24H
          </ScaleItem>
          <ScaleItem
            className={active === 2 ? "selected" : ""}
            onClick={() => setActive(2)}
            disabled
          >
            1W
          </ScaleItem>
          <ScaleItem
            className={active === 3 ? "selected" : ""}
            onClick={() => setActive(3)}
            disabled
          >
            1M
          </ScaleItem>
          <ScaleItem
            className={active === 4 ? "selected" : ""}
            onClick={() => setActive(4)}
            disabled
          >
            1Y
          </ScaleItem>
        </ScaleSelector>
      </TopSection>
      <GraphContainer>
        <GraphWrapper>
          <GraphInline>
            <img src={LineGraph} />
            <CurrencyColumn>
              <span>1 590.54 ETH</span>
              <span>1 580.73 ETH</span>
              <span>1 560.48 ETH</span>
              <span>1 540.28 ETH</span>
              <span>1 520.68 ETH</span>
              <span>1 500.58 ETH</span>
            </CurrencyColumn>
          </GraphInline>
          <DashedLine />
          <InlineRow>
            <span>05:00 PM</span>
            <span>08:00 PM</span>
            <span>11:00 PM</span>
            <span>2:00 AM</span>
            <span>5:00 AM</span>
            <span>8:00 AM</span>
            <span>11:00 AM</span>
            <span>2:00 PM</span>
          </InlineRow>{" "}
        </GraphWrapper>
      </GraphContainer>
    </GraphBlockWrapper>
  );
};

export default GraphBlock;
