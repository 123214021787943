import { UserWithData, useLogin, useUser } from "@thirdweb-dev/react";
import ActionsBlock from "../ActionsBlock/ActionBlock";
import { VerifyBlocksSection } from "./GatekeeperModal.styled";
import VerifyBlock from "../VerifyBlock/VerifyBlock";
import { IModalContentProps } from "../../../interfaces/IModalContent";
import useUserClaims from "../../../hooks/useUserClaims";
import { useClaims } from "../../../hooks/useClaims";
import { isKycClaim, isWalletScreeningClaim } from "../../../utils/types";
import {
  ClaimTopic,
  ClaimTopicId,
} from "../../../interfaces/enums/ResultStatusEnums";
import { useDeployIdentity } from "../../../hooks/useDeployIdentity";
import {
  ErrorNotification,
  LoadingNotification,
} from "../ModalStateIndicator/ModalStateIndicator";
import { ModalWrapper } from "./ModalWrapper";
import { useEffect, useState, useLayoutEffect, useContext } from "react";
import DeployIdentityBlock from "../DeployIdentity/DeployIdentityBlock";
import { ERRORS } from "../../../interfaces/enums/ErrorEnums";
import { Json } from "@thirdweb-dev/auth";
import WebSocketContext from "../../../context/WebSocketContext";

export type IExtendedUser = {
  identityAddress: string;
};

const GatekeeperModal = ({ handleClose }: IModalContentProps) => {
  const { user } = useUser<IExtendedUser, Json>();
  const { newIdentity, setUserAddress } = useContext(WebSocketContext);

  const {
    isLoading: isClaimsLoading,
    isError: isClaimsError,
    claims,
  } = useClaims();

  const {
    userClaims,
    isLoading: isUserClaimsLoading,
    isError: isUserClaimsError,
  } = useUserClaims();

  const { isDeployLoading, deployIdentity, deployError } = useDeployIdentity();

  const [identity, setIdentity] = useState<string | undefined | null>(
    user?.data?.identityAddress
  );

  const [errorMessage, setErrorMessage] = useState<undefined | ERRORS>(
    undefined
  );

  let isModalLoading = isClaimsLoading || isUserClaimsLoading;
  let isModalError = isClaimsError || isUserClaimsError;

  useEffect(() => {
    setUserAddress(user?.address ?? null);
  }, [user]);

  useLayoutEffect(() => {
    if (user?.data?.identityAddress) {
      setIdentity(user?.data?.identityAddress);
    } else if (newIdentity) {
      setIdentity(newIdentity);
    }
  }, [newIdentity, user?.data?.identityAddress]);

  const handleDeployIdentity = async () => {
    await deployIdentity();
  };

  const findUserClaim = (claimTopicId: ClaimTopicId) => {
    const userClaim = userClaims?.claims.find(
      (uc) => uc.claimTopicId === claimTopicId
    );
    return userClaim;
  };

  if (isModalLoading) {
    return (
      <ModalWrapper>
        <LoadingNotification />
      </ModalWrapper>
    );
  }

  if (isModalError) {
    return (
      <ModalWrapper>
        <ErrorNotification />
      </ModalWrapper>
    );
  }

  if (!!!identity) {
    return (
      <ModalWrapper error={deployError}>
        <DeployIdentityBlock
          isLoading={isDeployLoading}
          handleClick={handleDeployIdentity}
        />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper error={errorMessage}>
      <VerifyBlocksSection>
        {claims?.configuredClaims.map((confClaim) => {
          return (
            (isKycClaim(confClaim) || isWalletScreeningClaim(confClaim)) && (
              <VerifyBlock
                setErrorMessage={setErrorMessage}
                key={confClaim.claimTopicId}
                claimTopic={confClaim.claimTopic as ClaimTopic}
                claimTopicId={confClaim.claimTopicId}
                userClaim={findUserClaim(confClaim.claimTopicId)}
                identity={identity!}
              />
            )
          );
        })}
      </VerifyBlocksSection>
      <ActionsBlock />
    </ModalWrapper>
  );
};

export default GatekeeperModal;
