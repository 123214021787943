import { useAddress } from "@thirdweb-dev/react";
import {
  CloseIconButton,
  DisconnectBtn,
  ErrorMessageBlock,
  GatekeeperModalWrapper,
  LogoWrapper,
  WidgetTopSection,
} from "./GatekeeperModal.styled";
import logo from "../../../assets/logo.svg";
import { useLogoutOverwritten } from "../../../hooks/useLogout";
import OutlinedButton from "../../Common/Buttons/OutlinedButton/OutlinedButton";
import { useEffect, useState } from "react";
import CloseIcon from "../../../assets/X.svg";
import { ERRORS } from "../../../interfaces/enums/ErrorEnums";
import TextButton from "../../Common/Buttons/TextButton/TextButton";

interface IProps {
  error?: ERRORS;
  children?: React.ReactNode;
}

export const ModalWrapper = ({ error, children }: IProps) => {
  const address = useAddress();
  const { logout } = useLogoutOverwritten();
  const [closed, setClosed] = useState(false);

  const handleDisconnect = () => {
    logout();
  };

  const handleCloseError = () => {
    setClosed(!closed);
  };
  useEffect(() => {
    !!error && setClosed(false);
  }, [error]);

  return (
    <GatekeeperModalWrapper>
      <WidgetTopSection>
        <LogoWrapper>
          <img src={logo} alt="Gatekeeper logo" />
          <TextButton onClick={handleDisconnect}>Disconnect</TextButton>
        </LogoWrapper>
        <OutlinedButton address={address!} />
      </WidgetTopSection>
      {children}
      {!!error && (
        <ErrorMessageBlock closed={closed}>
          <span>{error}</span>
          <CloseIconButton onClick={handleCloseError}>
            <img alt="Close button" src={CloseIcon} />
          </CloseIconButton>
        </ErrorMessageBlock>
      )}
    </GatekeeperModalWrapper>
  );
};
