import styled from "styled-components";
import { ActionStatus } from "../../../interfaces/enums/ActionStatusEnums";
import { IActionStatusProps } from "../../../interfaces/ICommonProps";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
  STATUS_COLORS,
} from "../../../styles/variables";
import { VerifyBlockContainer } from "../VerifyBlock/VerifyBlock.styled";

export const ActionsBlockContainer = styled(VerifyBlockContainer)`
  min-height: 11.625rem;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.blockItem};
`;

export const ActionsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 7.8rem;
  width: 100%;
  gap: 0.6rem;

  &::-webkit-scrollbar {
    width: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(167, 169, 178, 0.5);
    border-radius: ${BORDER_RADII.medium};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${BORDER_RADII.medium};
    background-color: rgba(167, 169, 178, 0.8);
  }
`;

export const ListItemContainer = styled.div<IActionStatusProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme, status }) =>
    status === ActionStatus.ALLOWED
      ? theme.colors.text.label
      : theme.colors.text.accents.primary};
  font-weight: ${FONT_WEIGHTS.medium};
  font-size: ${FONT_SIZES.medium};
`;

export const ActionStatusIndicator = styled.div<IActionStatusProps>`
  height: 1rem;
  width: 1rem;
  border-radius: ${BORDER_RADII.small};
  ${({ status, theme }) => {
    switch (status) {
      case ActionStatus.ALLOWED: {
        return `
                    background-color: ${STATUS_COLORS.approved};

                `;
      }
      case ActionStatus.DENIED: {
        return `
                    background-color: ${theme.colors.text.accents.primary};
                `;
      }
      default:
        return `
                background-color: ${theme.colors.text.accents.primary};
                `;
    }
  }}
`;
