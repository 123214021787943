import styled from "styled-components";
import { ActionStatus } from "../../../interfaces/enums/ActionStatusEnums";
import { IActionStatusProps } from "../../../interfaces/ICommonProps";
import { BlockContainer } from "../../../styles/globalStyles";
import {
  BORDER_RADII,
  FONT_SIZES,
  FONT_WEIGHTS,
  STATUS_COLORS,
  WIDGET_FONT_FAMILY,
} from "../../../styles/variables";

interface Props {
  disabled?: boolean;
}

export const ActionsSectionContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 8.063rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
  flex-wrap: wrap;
`;

export const ActionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  gap: 0.5rem;
`;
export const AvailabilityInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;

  & > img {
    width: 4.688rem;
    margin: auto 0;
    margin-bottom: 0;
  }
`;

export const AvailabilityIndicator = styled.div<IActionStatusProps>`
  height: 1rem;
  width: fit-content;
  border-radius: ${BORDER_RADII.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: ${WIDGET_FONT_FAMILY};
  font-size: ${FONT_SIZES.extraSmall};
  font-weight: ${FONT_WEIGHTS.medium};
  padding: 0.25rem;
  gap: 0.25rem;

  &::before {
    content: "";
    height: 0.5rem;
    width: 0.5rem;
    border-radius: ${BORDER_RADII.circle};
    ${({ status }) => {
      switch (status) {
        case ActionStatus.ALLOWED: {
          return `
                    background-color: ${STATUS_COLORS.approved};
                `;
        }
        case ActionStatus.DENIED: {
          return `
                    background-color: ${STATUS_COLORS.denied};
                `;
        }
        default:
          return ``;
      }
    }}
  }
`;
export const ActionBlock = styled(BlockContainer)`
  width: 32%;
  flex-shrink: 1;
  flex-grow: 1;
  min-height: 8.813rem;
  height: 100%;
  padding: 1rem;
  position: relative;
  align-items: stretch;
  flex-direction: row;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

export const ActionTitle = styled.span`
  font-size: ${FONT_SIZES.large};
  font-weight: ${FONT_WEIGHTS.bold};
  color: ${({ theme }) => theme.colors.text.title};
`;

export const ActionDescription = styled.p`
  font-size: ${FONT_SIZES.small};
  font-weight: ${FONT_WEIGHTS.medium};
  color: ${({ theme }) => theme.colors.text.description};
`;

export const TakeActionBtn = styled.button<Props>`
  margin: auto 0;
  margin-bottom: 0;
  font-size: ${FONT_SIZES.small};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.buttons.ghost.disabledColor
      : theme.colors.buttons.ghost.color};
  border: none;
  background: none;
  outline: none;
  font-weight: ${FONT_WEIGHTS.bold};
  font-size: ${FONT_SIZES.small};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.375rem;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &:after {
    content: "";
    border: solid
      ${({ theme, disabled }) =>
        disabled
          ? theme.colors.buttons.ghost.disabledColor
          : theme.colors.buttons.ghost.color};
    border-width: 0 0.13rem 0.13rem 0;
    padding: 0.12rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.buttons.ghost.disabledColor
        : theme.colors.buttons.ghost.hoverColor};
    &:after {
      border: solid
        ${({ theme, disabled }) =>
          disabled
            ? theme.colors.buttons.ghost.disabledColor
            : theme.colors.buttons.ghost.hoverColor};
      border-width: 0 0.13rem 0.13rem 0;
      padding: 0.12rem;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
`;
