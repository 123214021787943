import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postUserClaim } from "../api";

export function useStartClaimVerification() {
  const queryClient = useQueryClient();
  const startClaimVerification = useMutation({
    mutationFn: postUserClaim,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userClaimsList"] });
    },
  });

  return {
    startClaimVerification: startClaimVerification.mutateAsync,
    isLoading: startClaimVerification.isLoading,
  };
}
