import {
  IClaim,
  IConfiguredClaim,
  IKycClaim,
  IWalletScreeningClaim,
} from "../interfaces/IClaims";

export function isKycClaim(
  claim: IClaim | IConfiguredClaim
): claim is IKycClaim {
  return (
    claim.claimTopic === "Gatekeeper.KYC" && claim.claimTopicId === 1000001
  );
}
export function isWalletScreeningClaim(
  claim: IClaim | IConfiguredClaim
): claim is IWalletScreeningClaim {
  return (
    claim.claimTopic === "Gatekeeper.WalletScreening" &&
    claim.claimTopicId === 1000002
  );
}
