import styled from "styled-components";
import { Container } from "../../../styles/globalStyles";
import { FONT_SIZES, FONT_WEIGHTS } from "../../../styles/variables";

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  min-height: 5.625rem;
  background-color: ${({ theme }) => theme.colors.footer};
  margin-top: auto;
  flex-shrink: 0;
  /* position: fixed;
   width: 100%;
   bottom: 0;
   left: 0; */
`;

export const FooterContainer = styled(Container)`
  display: flex;
  padding: 1.5rem 2rem;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 5.625rem;
  gap: 0.5rem;
`;

export const FooterSection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  font-size: ${FONT_SIZES.small};
  color: ${({ theme }) => theme.colors.text.footer};

  .title {
    font-weight: ${FONT_WEIGHTS.bold};
  }

  .last {
    margin: auto 0;
    margin-bottom: 0;
    font-weight: ${FONT_WEIGHTS.medium};
  }
`;

export const InlineRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: ${FONT_WEIGHTS.medium};
  flex-wrap: wrap;
  & > a {
    margin-right: 2rem;
  }
`;
